var NVIDIAGDC = NVIDIAGDC || {};
NVIDIAGDC.button = NVIDIAGDC.button || {} ;

NVIDIAGDC.Browser = {
    "os": "unknown"
};

//Detect which viewport/mediaquery is in use e.g. Tablet, Mobile or Desktop
NVIDIAGDC.Browser.getViewport  = function() {
 //return state after it has any double quotations removed
 return window.getComputedStyle(document.body,':after').content.replace(/"/g, "");
};

NVIDIAGDC.button.click =  function(anchor, url, islink, callback, target) {
// updating the domain cookie with the referrer href and element
//if (target && target !== "overlay") {
 // Cookies.set("nv.buttonReferrer", $(anchor).parent().html()+" | "+ JSON.stringify(window.location) , {domain:'nvidia.com'});
//}
  if (anchor && !target) {
    target = $(anchor).attr('target');
    if (target == '') {
      target = null;
      }
  }

  if (islink && !target) {
    target = '_self';
  }

  try {
    callback.call(anchor);
    } catch (e) {
      nvidiagdclog("open: " + e);
  }
  if (islink){
        if (target && target != '') {
            if (target === "overlay"){
            	var isMobile = NVIDIAGDC.Browser.getViewport() == "mobile" || NVIDIAGDC.Browser.getViewport() == "tablet";
                $('body').addClass('noscroll');
                var linkURL = $(anchor).data('href');
                var url = linkURL;
                var modalId = "";
                var modalWidth = "80%";
                var modalHeight = "80%";
                if (linkURL.indexOf('youtube')!= -1) {
                    //console.log("Its youtube ...");
                    var videoId = getUrlParam('v', linkURL);
                    var listId = getUrlParam('list', linkURL);
                    if (videoId != null ) {
                      url="https://www.youtube.com/embed/" + videoId;
                          if(listId!=null)
                              url= url + "?list=" + listId;
                    }
                  } else {
                    modalId=linkURL;
                    if(modalId.indexOf("#") !=-1){
                        //console.log("modalId  ---- " + modalId);
                        //console.log("$(modalId).data('width')  ----- " + $(modalId).data('width') );
                        //console.log("$(modalId).data('height') ----- " + $(modalId).data('height'));
                        if(typeof $(modalId).data('width')  != 'undefined' && $(modalId).data('width') != ''){
                        	modalWidth=$(modalId).data('width') + 'px';
                        }
                        if(typeof $(modalId).data('height')  != 'undefined' && $(modalId).data('height') != ''){
                    		modalHeight=$(modalId).data('height') + 'px';
                        }
                    }
                }

                //console.log(url);

                $(anchor).attr('href', url);
                var screenWidth = modalWidth;
                var screenHeight = modalHeight;
                if(isMobile || $(window).width()<=639){
                  screenWidth="95%";
                  screenHeight = "95%"
                }
                console.log(modalWidth, modalWidth, "modal");

                if (url.indexOf('#')!=-1){
                    //console.log("inline");
                	//var stkpgycord = $(window).scrollTop();
                  if($(window).width()<=639){
		                 screenWidth="450px";
                     screenHeight = "700px"

	                     }

                    $.colorbox({
                        inline:true,
                        href: url,
                        width:screenWidth,
                        maxHeight : screenHeight,
                      //Adding values for no scroll effect
                       /* fixed: true,
                       onOpen: function() {
                          var ycoord = stkpgycord * -1;
                          $('body').css('position','fixed').css('left','0px').css('right','0px').css('top',ycoord + 'px');
                        },
                        //added oncomplete function colorbox to override default overflow hidden to visible
                        onComplete : function() {
                        	$('#colorbox').css('overflow', 'visible');
                        	},
                        onClosed: function() {
                          $('body').css('position','').css('left','auto').css('right','auto').css('top','auto');
                          $(window).scrollTop(stkpgycord);
                        }*/
                    });

                } else {
                    //console.log("inline :) ");
                    $(".lb_iframe").colorbox({
                        html: url,
                        iframe: true,
                        inline: false,
                        width: screenWidth,
                        height: screenHeight

                    });
                }
            }
            else {
                window.open(url, target);
            }
        } else {
            window.location = url;
        }
  } else {
  //DO something
  }

}

getUrlParam = function(name, url){
  var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
    if (results==null){
       return null;
    }
    else{
       return results[1] || 0;
    }
}
//Below two functions kept outside of color box initiation to avoid unnecessary calls.
$('.dwnldclosebtn').click(function() {
    $.colorbox.close();
    $('body').removeClass('noscroll');
});
//Below Snippet for the Button PopUp auto adjust to screen orientation
var colorbox_resize = false;
$(window).on("orientationchange", function(event) {
//$(window).resize(function() {
    if (colorbox_resize)
        clearTimeout(colorbox_resize);
    colorbox_resize = setTimeout(function() {
    	if($('#cboxLoadedContent').is(':visible')){
   		 $(this).colorbox.resize({width:"100%"});
   		 $('#colorbox').css('overflow', 'visible');
   	 }
    }, 450);
});

NVIDIAGDC.button.callbacks = function(anchor) {

}
