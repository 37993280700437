;(function() {




    var initclose = function(){
      $('.search-form-container input[type="text"]').on('input propertychange', function() {
              var $this = $(this);
              var visible = Boolean($this.val());
              $this.siblings('.form-control-clear').toggleClass('hidden', !visible);
        }).trigger('propertychange');

    $('.form-control-clear').click(function() {
        $(this).siblings('input[type="text"]').val('')
          .trigger('propertychange').focus();
          $('#searchform').submit();
      });


  }

  var showClose = function(){
      $('.form-control-clear').show();
  }

  var hideFeatured = function(){
    $('.product-details-list-tile').each(function(k,v){
    if(k=== 0 || k === 1 ){  }
      else{ $(this).find('.image-cont').css('visibility','hidden'); }
    })
    $('.product-details-grid-tile').each(function(k1,v1){
    if(k1 === 0 || k1 === 1 ){   }
      else{ $(this).find('.image-cont').css('visibility','hidden'); }
  })
  }






  if(window.searchHelpers == null) {
          window.searchHelpers = {
                 initclose : initclose,
                 showClose : showClose,
                 hideFeatured: hideFeatured
          }
      }
}(window, undefined));
